/**
 * Throttles a function to be run only once per browser animation frame (16ms).
 *
 * example:
 *  function myScrollHandler(){ ...some expensive stuff...}
 *  const throttledScrollHandler = rafThrottle(myScrollHandler)
 *  window.document.onScroll(e => throttledScollHandler(e)) // will be called only once per browser frame
 */
export function rafThrottle<T extends (...arg: any) => void>(callback: T): T {
  let requestId: number | null = null;
  let lastArgs: Parameters<T>;

  const later = (context: ThisType<any>) => () => {
    requestId = null;
    callback.apply(context, lastArgs);
  };

  return function (this: ThisType<any>, ...args: Parameters<T>) {
    lastArgs = args;
    if (requestId === null) {
      requestId = requestAnimationFrame(later(this));
    }
  } as T;
}

/**
 * Throttles a function to be run only once per per given interval (in ms).
 *
 * example:
 *  function myScrollHandler(){ ...some expensive stuff...}
 *  const throttledScrollHandler = throttle(myScrollHandler, 300)
 *  window.document.onScroll(e => throttledScollHandler(e)) // will be called only once per 300ms interval;
 */
export function throttle<T extends (...args: any[]) => any>(func: T, limit: number): T {
  let timeout: NodeJS.Timeout;
  let lastRan: number;

  return function (this: ThisType<any>, ...args: any[]) {
    const currentTime = Date.now();
    if (!lastRan) {
      func.apply(this, args);
      lastRan = currentTime;
    } else {
      clearTimeout(timeout);
      if (currentTime - lastRan >= limit) {
        func.apply(this, args);
        lastRan = currentTime;
      } else {
        timeout = setTimeout(
          () => {
            func.apply(this, args);
            lastRan = Date.now();
          },
          limit - (currentTime - lastRan)
        );
      }
    }
  } as T;
}

/**
 * Throttle that executes the first call immediately, then enforces a fixed delay between
 * subsequent calls. If multiple calls happen during delay, only the last one is executed.
 * @param func The function to throttle
 * @param delay Milliseconds to wait between executions
 * @returns Throttled function
 */
export function immediateThrottle<T extends (...args: any[]) => any>(func: T, delay: number): T {
  let lastExecutionTime = 0;
  let scheduledTimeout: NodeJS.Timeout | null = null;
  let pendingArgs: Parameters<T> | null = null;
  let pendingThisArg: any = null;

  function executeLatestCall() {
    // Only execute if we have pending arguments
    if (pendingArgs !== null) {
      const argsToUse = pendingArgs;
      const thisToUse = pendingThisArg;

      // Clear pending data
      pendingArgs = null;
      pendingThisArg = null;
      scheduledTimeout = null;

      // Execute and update timestamp
      func.apply(thisToUse, argsToUse);
      lastExecutionTime = Date.now();
    }
  }

  return function (this: ThisType<any>, ...args: Parameters<T>) {
    const now = Date.now();
    const timeSinceLastExecution = now - lastExecutionTime;

    // Always store the latest call data
    pendingArgs = args;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    pendingThisArg = this;

    // If it's been longer than delay since last execution, execute immediately
    if (timeSinceLastExecution >= delay) {
      executeLatestCall();
    }
    // If no timeout is scheduled yet, schedule one
    else if (!scheduledTimeout) {
      const timeUntilNextExecution = delay - timeSinceLastExecution;
      scheduledTimeout = setTimeout(executeLatestCall, timeUntilNextExecution);
    }
    // Otherwise, we're waiting for the scheduled timeout with the latest arguments
  } as T;
}

/**
 * Debounces a function to be run only once after a given delay (in ms)
 *
 * example:
 *  function fetchSearchResults(text){ ...apiCall...}
 *  const debouncedFetch = debounce(fetchSearchResults, 200);
 *  <input type="search" onChange={(e) => debouncedFetch(e.target.value)} /> // executes the api call only when the user is not typing for 200ms
 */
export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): T {
  let timeoutId: NodeJS.Timeout;
  return function (this: ThisType<any>, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  } as T;
}
