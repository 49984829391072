import * as React from 'react';
import { cn } from '@aether/ui/utils';
import { Slot } from '@radix-ui/react-slot';

interface PageHeaderProps extends React.ComponentProps<'header'> {
  loading?: boolean;
}

export function PageHeader(props: PageHeaderProps) {
  const { children, className, loading } = props;
  return (
    <header
      className={cn(`centered-page z-20 border-b border-b-gray-300 bg-gray-50 pt-1`, className)}
      data-loading-header={loading ? 'true' : undefined}
    >
      {children}
    </header>
  );
}

interface PageTitleProps extends React.ComponentProps<'h1'> {
  asChild?: boolean;
}

export function PageTitle(props: PageTitleProps) {
  const { asChild, className, ...rest } = props;
  const Comp = asChild ? Slot : 'h1';
  return <Comp className={cn('text-2xl font-semibold tracking-tight', className)} {...rest} />;
}
