import * as React from 'react';
import { useMemo } from 'react';
import {
  type ApplyActionCodeMutationMutation,
  type LoginMutationMutation,
  type RegisterInput,
  type RequestNewInviteMutationMutation,
  type ResetPasswordMutationMutation,
  type SendResetPasswordMutationMutation,
  type SendVerificationEmailMutationMutation,
  type VerifyPasswordResetCodeMutationMutation,
} from '@aether/client-graphql/generated/graphql';
import { resetTracking, tracker } from '@aether/tracking';
import { NullKeysToUndefined } from '@aether/utils';
import { getUserAbility, type AfterAuth, type Rule } from '@aether/validation';
import { type FetchResult } from '@apollo/client';
import { createContextualCan } from '@casl/react';
import * as Sentry from '@sentry/react';
import userflow from 'userflow.js';

import { graphql, useMutation, useQuery, type DocumentType } from '@graphql';
import { USERFLOW_TOKEN } from '@/env';
import { getAuthApolloClient } from '@/graphql/client';

export type AuthUser = DocumentType<typeof _authUserFragment>;
export type CurrentOrganization = ReturnType<typeof useCurrentOrganization>[0] | null;

type AuthContextType = {
  user?: AuthUser;
  fetchingUser: boolean;
  refetchUser: () => Promise<AuthUser>;
  signInWithEmailAndPassword: ({
    email,
    password,
    afterAuth,
  }: {
    email: string;
    password: string;
    afterAuth?: AfterAuth;
  }) => Promise<FetchResult<LoginMutationMutation>>;
  signInWithEmailLink: (email: string, link: string) => Promise<AuthUser>;
  signInWithInviteCode: (code: string) => Promise<AuthUser>;
  signOut: () => Promise<any>;
  sendVerificationEmail: () => Promise<FetchResult<SendVerificationEmailMutationMutation>>;
  register: (input: RegisterInput) => Promise<AuthUser>;
  sendResetPasswordEmail: (email: string) => Promise<FetchResult<SendResetPasswordMutationMutation>>;
  verifyPasswordResetCode: (oobCode: string) => Promise<FetchResult<VerifyPasswordResetCodeMutationMutation>>;
  resetPassword: (input: {
    newPassword: string;
    oobCode: string;
  }) => Promise<FetchResult<ResetPasswordMutationMutation>>;
  requestNewInvite: (email: string) => Promise<FetchResult<RequestNewInviteMutationMutation>>;
  isAuthenticated: boolean;
  currentOrganization?: CurrentOrganization;
  changeCurrentOrganization: (organizationId: string | null) => void;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);
const defaultPermissions: Rule[] = [];
const AbilityContext = React.createContext(getUserAbility(defaultPermissions));

export const useAbilityContext = () => {
  const context = React.useContext(AbilityContext);
  if (context === undefined) {
    throw new Error('useAbilityContext must be used within an AbilityContext.Provider');
  }
  return context;
};
export type Ability = ReturnType<typeof useAbilityContext>;
export const Can = createContextualCan(AbilityContext.Consumer);

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export function AuthProvider({
  children,
  loadingPlaceholder,
}: {
  children: React.ReactNode;
  loadingPlaceholder?: React.ReactNode;
}) {
  const userRef = React.useRef<AuthUser | null>(null);
  const companyRef = React.useRef<AuthUser['organizations'][number] | null>(null);
  const { data, loading, refetch } = useQuery(authQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    skipPollAttempt: () => document.hasFocus() === false || companyRef.current?.planSummary?.featureAccess === 'public',
    client: getAuthApolloClient(),
  });
  const user = data?.me;
  userRef.current = user || null;

  const refetchUser = React.useCallback(async () => refetch().then(({ data }) => data.me), [refetch]);

  const [currentOrganization, changeCurrentOrganization] = useCurrentOrganization(user?.organizations);
  companyRef.current = currentOrganization;

  const ability = getUserAbility(
    currentOrganization?.planPermissions
      ? currentOrganization.planPermissions.map(({ __typename, ...permission }) => NullKeysToUndefined(permission))
      : defaultPermissions
  );

  React.useEffect(() => {
    if (user?.id) {
      Sentry.setUser({ id: user.id, email: user.email });
    }
    if (user && currentOrganization?.planSummary?.featureAccess !== 'public') {
      tracker.identify(user.clientId, {
        organization: currentOrganization?.id,
        role: currentOrganization?.role,
        email: user.email,
        name: user.displayName,
      });
      if (USERFLOW_TOKEN && !userflow.isIdentified() && user.displayName && user.passwordSet && user.emailVerified) {
        userflow.identify(
          user.clientId,
          {
            email: user.email,
            name: user.displayName,
            signed_up_at: user.createdAt,
          }
          // {
          //   signature: user.userflowToken,
          // }
        );
      }
    }
  }, [user, currentOrganization]); // don't use 'user' directly as a dependency, as a new reference is created at each refresh

  const signInWithEmailAndPassword = React.useCallback(
    async ({ email, password, afterAuth }: { email: string; password: string; afterAuth?: AfterAuth }) => {
      const apolloClient = getAuthApolloClient();
      const res = await apolloClient.mutate({ mutation: loginMutation, variables: { email, password, afterAuth } });
      await refetchUser();
      return res;
    },
    [refetchUser]
  );

  const signInWithEmailLink = React.useCallback(
    async (email: string, link: string) => {
      const apolloClient = getAuthApolloClient();
      return apolloClient
        .mutate({ mutation: signInWithEmailLinkMutation, variables: { email, link } })
        .then(refetchUser);
    },
    [refetchUser]
  );

  const signInWithInviteCode = React.useCallback(
    async (code: string) => {
      const apolloClient = getAuthApolloClient();
      return apolloClient
        .mutate({
          mutation: signInWithInviteCodeMutation,
          variables: { code },
        })
        .then(refetchUser);
    },
    [refetchUser]
  );

  const register = React.useCallback(
    async (input: RegisterInput) => {
      const apolloClient = getAuthApolloClient();
      return apolloClient.mutate({ mutation: registerMutation, variables: { input } }).then(refetchUser);
    },
    [refetchUser]
  );

  const signOut = React.useCallback(async () => {
    const apolloClient = getAuthApolloClient();
    return apolloClient.mutate({ mutation: logoutMutation }).finally(() => {
      ability.update([]);
      resetTracking();
      window.location.replace('/');
    });
  }, [ability]);

  return (
    <AuthContext.Provider
      value={{
        user,
        fetchingUser: loading,
        refetchUser,
        signInWithEmailAndPassword,
        signInWithEmailLink,
        signInWithInviteCode,
        signOut,
        sendVerificationEmail,
        register,
        sendResetPasswordEmail,
        verifyPasswordResetCode,
        requestNewInvite,
        resetPassword,
        isAuthenticated: !!user && currentOrganization?.planSummary?.featureAccess !== 'public',
        currentOrganization,
        changeCurrentOrganization,
      }}
    >
      <AbilityContext.Provider value={ability}>
        {loading && !data && loadingPlaceholder ? loadingPlaceholder : children}
      </AbilityContext.Provider>
    </AuthContext.Provider>
  );
}

async function sendVerificationEmail() {
  const client = getAuthApolloClient();
  return client.mutate({ mutation: sendVerificationEmailMutation });
}

async function sendResetPasswordEmail(email: string) {
  const client = getAuthApolloClient();
  return client.mutate({ mutation: sendResetPasswordMutation, variables: { email } });
}

async function verifyPasswordResetCode(oobCode: string) {
  const client = getAuthApolloClient();
  return client.mutate({ mutation: verifyPasswordResetCodeMutation, variables: { oobCode } });
}

async function resetPassword(variables: { newPassword: string; oobCode: string }) {
  const client = getAuthApolloClient();
  return client.mutate({ mutation: resetPasswordMutation, variables });
}

async function requestNewInvite(email: string) {
  const client = getAuthApolloClient();
  return client.mutate({ mutation: requestNewInviteMutation, variables: { email } });
}

export function useActionCode(oobCode: string) {
  const hasRunOnce = React.useRef(false);
  const data = React.useRef<FetchResult<ApplyActionCodeMutationMutation>>();
  const { refetchUser } = useAuth();
  const [applyActionCode, { loading, error }] = useMutation(applyActionCodeMutation, {
    client: getAuthApolloClient(),
  });

  React.useEffect(() => {
    if (hasRunOnce.current) return undefined;
    hasRunOnce.current = true; // hack to prevent react-18 to run this effect twice in dev
    applyActionCode({ variables: { oobCode } }).then(res => {
      refetchUser();
      data.current = res;
    });
  }, [oobCode, refetchUser, applyActionCode]);
  return { loading, error, data: data.current };
}

graphql(/* GraphQL */ `
  fragment AuthUser on User {
    id
    clientId
    displayName
    email
    emailVerified
    country
    profession
    passwordSet
    photoURL
    lastSignInTime
    declaredOrg
    createdAt
    isAdmin
    userflowToken
    organizations {
      id
      name
      slug
      emailDomain
      role
      planSummary {
        id
        planId
        displayName
        reference
        isTrial
        trialEndsAt
        featureAccess
        apiAccess
      }
      planPermissions {
        action
        subject
        fields
        conditions
        inverted
        reason
      }
    }
  }
`);

const _authUserFragment = graphql(/* GraphQL */ `
  fragment AuthUserWithImpersonation on User {
    ...AuthUser
    impersonatedBy {
      ...AuthUser
    }
  }
`);

/**
 * Returns the current organization and a function to change it.
 * The current organization ID is stored in localStorage.
 * If the current organization ID is not found in the user's organizations, it is set to the first organization in the list, or null if there are no organizations.
 */
function useCurrentOrganization(userOrganizations: AuthUser['organizations'] | undefined) {
  const [storedOrgId, setStoredOrgId] = React.useState<string | null>(() => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith('currentOrganizationId='));
    return cookie ? cookie.split('=')[1] : null;
  });

  const org =
    userOrganizations && (userOrganizations.find(org => org.id === storedOrgId) || userOrganizations[0] || null);
  const { data, loading } = useQuery(currentOrganizationQuery, {
    skip: !org,
    variables: { id: org?.id || '' },
    client: getAuthApolloClient(),
  });

  const exposedOrganization = useMemo(() => {
    if (!org) return null;
    if (data?.organization) return data.organization;
    return { members: [], teams: [], planSummary: null, ...org };
  }, [data, org]);

  const changeOrganization = React.useCallback(
    (organizationId: string | null) => {
      if (!userOrganizations) return;
      const organization = organizationId ? userOrganizations.find(org => org.id === organizationId) : null;
      if (organization) {
        setStoredOrgId(organization.id);
        document.cookie = `currentOrganizationId=${organization.id}; path=/; domain=${window.location.hostname.split('.').slice(-2).join('.')}; max-age=31536000`; // 1 year
      } else {
        setStoredOrgId(null);
        document.cookie = 'currentOrganizationId=; path=/; max-age=0';
      }
    },
    [userOrganizations]
  );

  React.useEffect(() => {
    if (!userOrganizations) return;
    if (storedOrgId !== exposedOrganization?.id) {
      changeOrganization(exposedOrganization?.id || null);
    }
  }, [storedOrgId, exposedOrganization, changeOrganization, userOrganizations]);

  if (!userOrganizations) return [null, () => {}, false] as const;

  return [exposedOrganization, changeOrganization, loading] as const;
}

// The query name `AuthQuery` is used to fetch the persisted query in the
// dragon app (apps/dragon/app/root.tsx), so it should not be changed
export const authQuery = graphql(/* GraphQL */ `
  query AuthQuery {
    me {
      ...AuthUserWithImpersonation
    }
  }
`);

export const currentOrganizationQuery = graphql(/* GraphQL */ `
  query CurrentOrganizationQuery($id: ID!) {
    organization(id: $id) {
      ...CurrentOrganization
    }
  }
`);

const _organizationFragment = graphql(/* GraphQL */ `
  fragment CurrentOrganization on Organization {
    ...OrganizationDetails
    role
    ...OrganizationMembers
    ...OrganizationTeams
    planSummary {
      ...OrganizationPlanSummary
    }
    planPermissions {
      action
      subject
      inverted
    }
  }
`);

const _organizationPlanSummaryFragment = graphql(/* GraphQL */ `
  fragment OrganizationPlanSummary on OrganizationPlanSummary {
    id
    planId
    displayName
    reference
    isTrial
    trialEndsAt
    featureAccess
    apiAccess
  }
`);

const loginMutation = graphql(/* GraphQL */ `
  mutation LoginMutation($email: EmailAddress!, $password: String!, $afterAuth: AfterAuthInput) {
    login(email: $email, password: $password, afterAuth: $afterAuth) {
      success
      message
      navigate
    }
  }
`);

const logoutMutation = graphql(/* GraphQL */ `
  mutation LogoutMutation {
    logout {
      success
      message
    }
  }
`);

const registerMutation = graphql(/* GraphQL */ `
  mutation RegisterMutation($input: RegisterInput!) {
    register(input: $input) {
      success
      message
    }
  }
`);

const applyActionCodeMutation = graphql(/* GraphQL */ `
  mutation ApplyActionCodeMutation($oobCode: String!) {
    applyActionCode(oobCode: $oobCode) {
      success
      message
      navigate
    }
  }
`);

const signInWithEmailLinkMutation = graphql(/* GraphQL */ `
  mutation SignInWithEmailLinkMutation($email: EmailAddress!, $link: SignInLink!) {
    loginWithEmailLink(email: $email, link: $link) {
      success
      message
    }
  }
`);

const signInWithInviteCodeMutation = graphql(/* GraphQL */ `
  mutation SignInWithInviteCodeMutation($code: String!) {
    loginWithInviteCode(code: $code) {
      success
      message
    }
  }
`);

const sendVerificationEmailMutation = graphql(/* GraphQL */ `
  mutation SendVerificationEmailMutation {
    sendVerificationEmail {
      success
      message
    }
  }
`);

const resetPasswordMutation = graphql(/* GraphQL */ `
  mutation ResetPasswordMutation($newPassword: Password!, $oobCode: String!) {
    resetPassword(newPassword: $newPassword, oobCode: $oobCode) {
      success
      message
    }
  }
`);

const sendResetPasswordMutation = graphql(/* GraphQL */ `
  mutation SendResetPasswordMutation($email: EmailAddress!) {
    sendResetPassword(email: $email) {
      success
      message
    }
  }
`);

const verifyPasswordResetCodeMutation = graphql(/* GraphQL */ `
  mutation VerifyPasswordResetCodeMutation($oobCode: String!) {
    verifyPasswordResetCode(oobCode: $oobCode) {
      success
      message
    }
  }
`);

const requestNewInviteMutation = graphql(/* GraphQL */ `
  mutation RequestNewInviteMutation($email: EmailAddress!) {
    requestNewInvite(email: $email) {
      success
      message
    }
  }
`);
