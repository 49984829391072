import * as Sentry from '@sentry/react';

const environment =
  process.env.NODE_ENV === 'production'
    ? window.location.hostname === 'app.portfoliodragon.com'
      ? 'production'
      : 'staging'
    : 'development';

Sentry.init({
  dsn: process.env.NX_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  release: process.env.NX_AUTOCIO_ONE_VERSION,
  beforeSend: event => {
    if (environment === 'development') {
      console.info('Development Blocked Sentry event', event);
      return null;
    }
    return event;
  },
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'Received status code 401', 'Received status code 400'],
  environment,
  tracesSampleRate: process.env.NX_SENTRY_TRACE_SAMPLE_RATE
    ? Number(process.env.NX_SENTRY_TRACE_SAMPLE_RATE)
    : undefined,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
