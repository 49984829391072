import { useEffect } from 'react';
import { toast } from '@aether/ui/Toast';
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAbilityContext, useAuth } from '../utils/auth';

const unAuthRoutes = ['/auth/*', '/funds/*'];
export const ON_BEHALF_OF_STORAGE_KEY = 'onBehalfOfId';

export const AuthController = () => {
  const { user, isAuthenticated, fetchingUser } = useAuth();
  const location = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const ability = useAbilityContext();
  useEffect(() => {
    if (fetchingUser) return;

    if (localStorage.getItem(ON_BEHALF_OF_STORAGE_KEY) && !user?.impersonatedBy) {
      toast.error('Unable to impersonate user.');
      localStorage.removeItem(ON_BEHALF_OF_STORAGE_KEY);
    }

    const redirectTo =
      params.get('redirectTo') ?? encodeURIComponent(location.pathname + location.search + location.hash);
    if (user && isAuthenticated) {
      if (!location.pathname.includes('/auth')) {
        if (!user.emailVerified) navigate(`/auth/email-verification`);
        if (ability.cannot('read', 'App') && !location.pathname.includes('/create-organization'))
          navigate(`/create-organization?redirectTo=${redirectTo}`);
      }

      if (!user.passwordSet && location.pathname !== '/account/complete-signup') navigate(`/account/complete-signup`);
    } else if (!isUnAuthRoute(location.pathname)) {
      navigate(`/auth/login?redirectTo=${redirectTo}`, {
        replace: true,
      });
    }
  }, [user, location, navigate, ability, isAuthenticated, params, fetchingUser]);

  return null;
};

const isUnAuthRoute = (pathname: string) => unAuthRoutes.some(path => Boolean(matchPath({ path }, pathname)));
