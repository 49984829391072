import { useEffect, useState } from 'react';
import { OmniSearch as OmniSearchUI, type OmniSearchProps } from '@aether/ui/app-shared/OmniSearch';
import { matchPath, useLocation } from 'react-router-dom';

import { useOmniSearchItems } from './OmniSearchItemsProvider';

export const OmniSearch = ({
  placeholder = 'Search for fund ISIN or portfolio name …',
  ...props
}: Omit<OmniSearchProps, 'data'>) => {
  const location = useLocation();
  const items = useOmniSearchItems();
  const [isSearchOpen, setIsSearchOpen] = useState(
    Boolean(matchPath({ path: '/analyze-portfolio' }, location.pathname))
  );

  useEffect(() => {
    setIsSearchOpen(Boolean(matchPath({ path: '/analyze-portfolio' }, location.pathname)));
  }, [location.pathname]);

  return (
    <OmniSearchUI {...props} data={items} placeholder={placeholder} isOpen={isSearchOpen} hideFundsOnEmptySearch />
  );
};
