import React from 'react';
import { Alert } from '@aether/ui/Alert';
import { ButtonNew } from '@aether/ui/ButtonNew';
import { cn } from '@aether/ui/utils';
import { formatDateTimeForDisplay } from '@aether/utils/date-helpers';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Link, Outlet } from 'react-router-dom';

import { AuthLink } from '@/pages/Auth/components/AuthLink';
import { useAuth } from '@/utils/auth';

import { OmniSearch } from '../OmniSearch/OmniSearch';
import { OmniSearchItemsProvider } from '../OmniSearch/OmniSearchItemsProvider';
import { PageLoader } from '../PageLoader/PageLoader';
import SideNavBar from './SideNavBar';

dayjs.extend(relativeTime);
const SIDEBAR_STATE_KEY = 'isSideNavBarOpen';

export default function Layout() {
  const { isAuthenticated, currentOrganization } = useAuth();

  // Sidebar specials
  const [isOpen, setIsOpen] = React.useState<boolean>(['true', null].includes(localStorage.getItem(SIDEBAR_STATE_KEY)));
  const sidebarRef = React.useRef<HTMLDivElement>(null);
  // Check if there might be a scrollbar, if so, increase the width of the sidebar (won't be triggered on resize but that's ok)
  const isScrollable = Number(sidebarRef?.current?.scrollHeight) > Number(sidebarRef?.current?.offsetHeight);
  const sideBarWidth = isOpen ? (isScrollable ? 245 : 235) : isScrollable ? 80 : 65;

  const handleToggle = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    localStorage.setItem(SIDEBAR_STATE_KEY, String(newState));
  };

  const displayTrialBanner = currentOrganization?.planSummary?.isTrial;

  return (
    <OmniSearchItemsProvider>
      <PageLoader />
      <SideNavBar isOpen={isOpen} width={sideBarWidth} containerRef={sidebarRef} handleToggle={handleToggle} />
      <main
        className="flex h-screen flex-1 flex-col bg-white"
        style={{
          width: `calc(100% - ${sideBarWidth}px)`,
          marginLeft: `${sideBarWidth}px`,
          transition: 'width 0.3s ease-in-out, margin 0.3s ease-in-out',
        }}
      >
        {displayTrialBanner && (
          <div
            className="fixed top-0 z-30 w-full transition-all duration-300"
            style={{ width: `calc(100% - ${sideBarWidth}px)` }}
          >
            <TrialStatusIndicator
              pendingPayment={false}
              trialExpiresAt={currentOrganization?.planSummary?.trialEndsAt || ''}
              isOwner={currentOrganization?.role === 'owner'}
            />
          </div>
        )}
        {!isAuthenticated && (
          <div
            className="centered-page fixed top-0 z-30 grid w-full grid-cols-[1fr_auto] gap-4 border-b border-gray-300 bg-white py-3 transition-all duration-300"
            style={{ width: `calc(100% - ${sideBarWidth}px)` }}
          >
            <OmniSearch />
            <div className="shrink-0 space-x-4">
              <ButtonNew variant="primary" iconBefore="ic:outline-add" asChild>
                <AuthLink to="/auth/register">Create Account</AuthLink>
              </ButtonNew>
              <ButtonNew variant="secondary" iconBefore="ic:outline-edit" asChild>
                <AuthLink to="/auth/login">Sign In</AuthLink>
              </ButtonNew>
            </div>
          </div>
        )}
        <div className={cn('flex h-screen flex-1 flex-col', (!isAuthenticated || displayTrialBanner) && 'pt-16')}>
          <Outlet />
        </div>
      </main>
    </OmniSearchItemsProvider>
  );
}

function TrialStatusIndicator(props: { pendingPayment: boolean; trialExpiresAt: string; isOwner: boolean }) {
  const { pendingPayment, trialExpiresAt, isOwner } = props;
  const trialExpiresDate = dayjs(trialExpiresAt);
  const trialDays = trialExpiresDate.diff(dayjs(), 'day');
  const trialStatus = pendingPayment ? 'pendingPayment' : dayjs().isAfter(trialExpiresDate) ? 'expired' : 'active';
  switch (trialStatus) {
    case 'active':
      return (
        <div className="px-4 pt-4">
          <Alert level="success" className="border-[#0D9453] bg-[#3D6C4D] [&>*]:text-white">
            <p>
              Your trial expires{' '}
              <time
                dateTime={dayjs(trialExpiresAt).format('YYYY-MM-DDTHH:MM')}
                title={formatDateTimeForDisplay(trialExpiresAt!)}
              >
                {
                  /* `.fromNow()` can round to months but we only want days or below  */
                  trialDays > 1 ? `in ${trialDays} days` : dayjs(trialExpiresAt).fromNow()
                }
              </time>
              .{' '}
              {isOwner && (
                <>
                  Please{' '}
                  <Link to="/organization/settings" className="underline">
                    click here to subscribe
                  </Link>
                </>
              )}
            </p>
          </Alert>
        </div>
      );
    case 'expired':
      return (
        <div className="px-4 pt-4">
          <Alert level="warning">
            <p>
              Your trial has expired. Please{' '}
              {isOwner ? (
                <>
                  <Link to="/organization/settings" className="underline">
                    click here to subscribe
                  </Link>
                </>
              ) : (
                'contact your administrator to subscribe'
              )}
              .
            </p>
          </Alert>
        </div>
      );
    case 'pendingPayment':
      return (
        <div className="px-4 pt-4">
          <Alert level="info">
            <p>
              Your subscription is{' '}
              <Link to="/organization/settings" className="underline">
                pending confirmation
              </Link>
              . You can continue using the platform in the meantime.
            </p>
          </Alert>
        </div>
      );
    default:
      return null;
  }
}
