import * as React from 'react';
import arabesqueAi from '@aether/ui/app-shared/assets/arabesque-ai.svg';
import { Icon } from '@aether/ui/Icon';
import * as Menu from '@aether/ui/Menu';
import { Tooltip } from '@aether/ui/Tooltip';
import { cn } from '@aether/ui/utils';
import { getWebsiteOrigin } from '@aether/utils/services/linking-service';
import { Link, useLocation, useParams } from 'react-router-dom';

import { ON_BEHALF_OF_STORAGE_KEY } from '@/controllers/AuthController';
import { useAuth } from '@/utils/auth';

import { OmniSearch } from '../OmniSearch/OmniSearch';

export default function SideNavBar({
  isOpen,
  width,
  containerRef,
  handleToggle,
}: {
  isOpen: boolean;
  handleToggle: () => void;
  width: number;
  containerRef: React.RefObject<HTMLDivElement>;
}) {
  const { user, isAuthenticated, currentOrganization, changeCurrentOrganization } = useAuth();

  return (
    <nav
      className="fixed top-0 z-50 flex h-screen flex-col overflow-x-hidden bg-grey-900 text-gray-200"
      style={{
        width: `${width}px`,
        transition: 'width 0.3s ease-in-out',
      }}
      id="main-side-nav-bar"
    >
      <div ref={containerRef} className="flex flex-1 flex-col gap-8 overflow-y-auto overflow-x-hidden px-4 pb-14 pt-6">
        <div
          className="absolute bottom-0 z-10 overflow-hidden bg-grey-900 pb-2"
          style={{
            width: isOpen ? '80%' : '34px',
            transition: 'width 0.3s ease-in-out',
          }}
        >
          <NavLink
            onClick={handleToggle}
            isOpen={isOpen}
            title={isOpen ? 'Collapse' : 'Expand'}
            icon={isOpen ? 'iconamoon:arrow-left-2-light' : 'iconamoon:arrow-right-2-light'}
            iconClassName="m-0 mr-2 h-[32px] min-h-[32px] w-[34px] min-w-[34px] rounded-md bg-portfolio-500 p-1 text-white transition-colors duration-500 group-hover:bg-white group-hover:text-gray-700"
          />
        </div>
        <Link to={isAuthenticated ? '/welcome' : getWebsiteOrigin() || '/'} className="w-full text-nowrap">
          <img src="/images/dragon.svg" alt="Arabesque AI" className="m-auto mr-2 inline-block w-[33px] align-middle" />
          <div
            style={{
              opacity: isOpen ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
            }}
            className="inline-block align-middle"
          >
            <div className="mr-2 whitespace-nowrap text-[18px] font-semibold leading-[22px] text-gray-200">
              Portfolio Dragon
            </div>
            <div className="flex items-center gap-1 whitespace-nowrap text-[10px] leading-3 text-gray-400">
              <span>powered by</span> <img src={arabesqueAi} alt="arabesque.ai" className="inline-block w-[86px]" />
            </div>
          </div>
        </Link>
        <OmniSearch hasKeyboardOpenShortcut />
        <div>
          {currentOrganization && currentOrganization.planSummary?.featureAccess !== 'public' && (
            <NavLinkHeader isOpen={isOpen} className="flex items-center justify-between">
              <div className="overflow-hidden text-ellipsis">{currentOrganization.name}</div>
              {user?.organizations?.length && user.organizations.length > 1 && (
                <Menu.MenuProvider>
                  <Menu.MenuButton>
                    <Icon icon="ic:outline-keyboard-arrow-down" />
                  </Menu.MenuButton>
                  <Menu.Menu alignWithIcons>
                    {user.organizations.map(o => (
                      <Menu.MenuItem
                        key={o.id}
                        iconBefore={o.id === currentOrganization.id ? 'ic:outline-check' : undefined}
                        onClick={() => {
                          changeCurrentOrganization(o.id);
                          window.location.reload();
                        }}
                      >
                        {o.name}
                      </Menu.MenuItem>
                    ))}
                  </Menu.Menu>
                </Menu.MenuProvider>
              )}
            </NavLinkHeader>
          )}
          <NavLink
            to="/portfolios/all"
            pathMatcher={/^\/portfolios/}
            icon="ic:outline-reorder"
            title="Portfolios"
            isOpen={isOpen}
          />
          <NavLink
            to="/strategy/upload-holdings"
            pathMatcher={/^\/strategy\/upload-holdings/}
            icon="ic:outline-upload-file"
            title="Upload new portfolio"
            isOpen={isOpen}
          />
          <NavLink
            icon="ic:outline-tune"
            title="Build new portfolio"
            to="/configuration"
            pathMatcher={/^\/configuration/}
            isOpen={isOpen}
          />
        </div>

        <div>
          <NavLinkHeader isOpen={isOpen}>Features</NavLinkHeader>
          <PortfolioNavLink
            icon="ic:outline-insights"
            title="Analytics"
            subPath="/analytics"
            isOpen={isOpen}
            pathMatcher={/^\/[^/]+\/[^/]+\/analytics/}
          />
          <PortfolioNavLink
            icon="ic:outline-format-list-numbered"
            title="Holdings"
            subPath="/holdings"
            isOpen={isOpen}
            pathMatcher={/^\/[^/]+\/[^/]+\/holdings/}
          />
          <PortfolioNavLink
            icon="ic:outline-line-style"
            title="Attribution"
            subPath="/attribution"
            pathMatcher={/^\/[^/]+\/[^/]+\/attribution/}
            isOpen={isOpen}
          />
          <PortfolioNavLink
            icon="ic:outline-co2"
            title="Sustainability"
            subPath="/sustainability"
            pathMatcher={/^\/[^/]+\/[^/]+\/sustainability/}
            isOpen={isOpen}
          />
          <PortfolioNavLink
            icon="ic:outline-balance"
            title="AI rebalance"
            subPath="/rebalance/holdings"
            pathMatcher={/^\/[^/]+\/[^/]+\/rebalance/}
            isOpen={isOpen}
          />
          <PortfolioNavLink
            icon="ic:outline-tune"
            title="Configuration"
            subPath="/configuration"
            pathMatcher={/^\/[^/]+\/[^/]+\/configuration/}
            isOpen={isOpen}
          />

          <PortfolioNavLink
            icon="material-symbols:file-save-outline"
            title="Factsheet"
            subPath="/factsheet"
            pathMatcher={/^\/[^/]+\/[^/]+\/factsheet/}
            isOpen={isOpen}
          />
          <PortfolioNavLink
            icon="ic:outline-auto-awesome"
            title="Dragon Assistant"
            subPath="/dragon-assistant"
            pathMatcher={/^\/[^/]+\/[^/]+\/dragon-assistant/}
            isOpen={isOpen}
            className="text-warning-200"
            iconClassName="text-warning-200"
          />
        </div>
        <div>
          <NavLinkHeader isOpen={isOpen}>Settings</NavLinkHeader>
          <NavLink soon icon="material-symbols:data-table-outline" title="Data management" isOpen={isOpen} />
          <NavLink soon icon="ic:outline-tune" title="Custom factsheets" isOpen={isOpen} />
        </div>
        <div className="mt-auto">
          {isAuthenticated && (
            <div>
              <NavLinkHeader isOpen={isOpen}>Account</NavLinkHeader>
              <div
                className="-mt-2 mb-2 overflow-hidden text-ellipsis text-xs text-gray-200"
                style={{
                  opacity: isOpen ? 1 : 0,
                  transition: 'opacity 0.6s ease-in-out',
                }}
              >
                {user?.email}
              </div>
              {user?.impersonatedBy && (
                <>
                  <NavLinkHeader isOpen={isOpen}>Impersonated by</NavLinkHeader>
                  <div
                    className="-mt-2 mb-2 overflow-hidden text-ellipsis text-xs text-red-500"
                    style={{
                      opacity: isOpen ? 1 : 0,
                      transition: 'opacity 0.6s ease-in-out',
                    }}
                  >
                    {user.impersonatedBy.email}
                  </div>
                  <NavLink
                    onClick={() => {
                      localStorage.removeItem(ON_BEHALF_OF_STORAGE_KEY);
                      window.location.href = '/admin/user-management';
                    }}
                    isOpen={isOpen}
                    title="Switch to my account"
                    icon="ic:outline-supervisor-account"
                    iconClassName="m-0 mr-2 h-[32px] min-h-[32px] w-[34px] min-w-[34px] rounded-md bg-portfolio-500 p-1 text-white transition-colors duration-500 group-hover:bg-white group-hover:text-gray-700"
                  />
                </>
              )}
              <NavLink
                to="/account/edit-profile"
                pathMatcher={/^\/account\/edit-profile/}
                icon="ic:outline-person"
                title="My profile"
                isOpen={isOpen}
              />
              {currentOrganization && (
                <NavLink
                  to="/organization/settings"
                  pathMatcher={/^\/organization\//}
                  icon="ic:outline-business"
                  title="Organization settings"
                  isOpen={isOpen}
                />
              )}
              <NavLink
                to="/auth/logout"
                icon="ic:outline-logout"
                title="Log out"
                isOpen={isOpen}
                data-testid="ac-logout"
              />
            </div>
          )}
        </div>
        <Footer isOpen={isOpen} />
      </div>
    </nav>
  );
}

const NavLinkHeader = ({
  isOpen,
  children,
  className,
}: {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      style={{
        opacity: isOpen ? 1 : 0,
        transition: 'opacity 0.6s ease-in-out',
      }}
      className={cn('mb-2 overflow-hidden whitespace-nowrap text-sm font-medium uppercase leading-5', className)}
    >
      {children}
    </div>
  );
};

const NavLink = React.forwardRef<
  HTMLButtonElement,
  {
    onClick?: () => void;
    to?: string;
    title: string;
    icon: string;
    isOpen?: boolean;
    target?: string;
    className?: string;
    iconClassName?: string;
    soon?: boolean;
    disabled?: boolean;
    pathMatcher?: RegExp;
  }
>(function NavLink(
  { title, icon, pathMatcher, isOpen = false, target, className, iconClassName, soon, disabled, ...rest },
  forwardedRef
) {
  const { pathname } = useLocation();
  const Comp = !soon && !disabled && rest.to ? Link : 'button';
  return (
    <Tooltip
      hidden={isOpen}
      content={
        <>
          {title}
          {soon && ' - Coming soon'}
        </>
      }
      sideOffset={22}
      className="border-0"
      asChild
      side="right"
    >
      <Comp
        // @ts-expect-error not worth fixing
        ref={forwardedRef}
        className={cn(
          'focus-visible:ring-ring group relative flex h-8 w-full items-center justify-start whitespace-nowrap rounded-md text-sm hover:bg-gray-700 hover:text-grey-100',
          rest.to && pathMatcher?.test(pathname) && 'bg-gray-700 text-gray-100',
          className
        )}
        target={target}
        {...rest}
      >
        <Icon
          className={cn(
            'mx-1.5 inline-block h-[20px] min-h-[20px] w-[20px] min-w-[20px] text-gray-400 group-hover:text-white',
            iconClassName
          )}
          icon={icon}
        />
        <span
          style={{
            opacity: isOpen ? 1 : 0,
            transition: 'opacity 0.6s ease-in-out',
          }}
          className="inline-block text-sm font-medium"
        >
          {title}
          {soon && (
            <span className="absolute inset-0 left-8 py-1.5 text-left opacity-0 backdrop-blur-md transition-all duration-200 group-hover:opacity-100">
              Coming soon
            </span>
          )}
        </span>
      </Comp>
    </Tooltip>
  );
});

function PortfolioNavLink(
  props: {
    subPath: string;
    icon: string;
    title: string;
    isOpen?: boolean;
    soon?: boolean;
    disabled?: boolean;
  } & React.ComponentProps<typeof NavLink>
) {
  const { subPath, ...rest } = props;
  const { search } = useLocation();
  const { fundId, portfolioId: strategyId, enhancedId } = useParams();
  const basePath = portfolioBasePath({ fundId, strategyId, enhancedId });
  if (props.soon || basePath) {
    return <NavLink {...rest} to={`${basePath}${subPath}${search}`} />;
  }
  return (
    <OmniSearch itemUrl={data => `${data.url || ''}${subPath}${search}`}>
      <NavLink {...rest} />
    </OmniSearch>
  );
}

function portfolioBasePath({
  strategyId,
  fundId,
  enhancedId,
}: {
  fundId?: string;
  strategyId?: string;
  enhancedId?: string;
}) {
  return strategyId
    ? `/strategies/${strategyId}`
    : fundId
      ? `/funds/${fundId}`
      : enhancedId
        ? `/ai-enhanced/${enhancedId}`
        : null;
}

const Footer = ({ isOpen }: { isOpen: boolean }) => {
  const { isAuthenticated } = useAuth();
  const getDragonURL = getWebsiteOrigin() || '/';

  const PopupContent = (
    <>
      <div className="text-grey-200">&copy; {new Date().getFullYear()}, Portfolio Dragon</div>
      <nav className="space-y-1 text-grey-500">
        <Link to={`${getDragonURL}/platform-service-terms`} className="block hover:text-white">
          Platform service terms
        </Link>
        <Link to={`${getDragonURL}/privacy-policy`} className="block hover:text-white">
          Privacy policy
        </Link>
        <Link to={`${getDragonURL}/cookie-policy`} className="block hover:text-white">
          Cookie policy
        </Link>
        <Link
          to={isAuthenticated ? '/contact' : 'https://www.arabesque.com/ai/contact-us/'}
          className="block hover:text-white"
        >
          Contact us
        </Link>
        {isAuthenticated && (
          <Link to="/faq" className="block hover:text-white">
            Help
          </Link>
        )}
      </nav>
    </>
  );

  return (
    <Tooltip content={<div className="space-y-1 text-sm">{PopupContent}</div>} sideOffset={22} side="right" asChild>
      <div
        className={
          'focus-visible:ring-ring group relative flex h-8 w-full items-center justify-start whitespace-nowrap rounded-md text-sm hover:bg-gray-700 hover:text-grey-100'
        }
      >
        <Icon
          className="mx-1.5 inline-block h-[35px] min-h-[35px] w-[20px] min-w-[20px] text-gray-400 group-hover:text-white"
          icon={'ic:outline-info'}
        />
        <span
          style={{
            opacity: isOpen ? 1 : 0,
            transition: 'opacity 0.6s ease-in-out',
          }}
          className="whitespace-nowrap"
        >
          Legal & Support <Icon icon="ic:outline-keyboard-arrow-right" className="inline" />
        </span>
      </div>
    </Tooltip>
  );
};
