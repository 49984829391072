import { createContext, useContext, useMemo, type ReactNode } from 'react';
import { type LayoutOmnisearchQueryQuery } from '@aether/client-graphql/generated/graphql';
import { type Data } from '@aether/ui/app-shared/OmniSearch';
import { getAppOrigin } from '@aether/utils/services/linking-service';
import { type ApolloQueryResult } from '@apollo/client';

import { graphql, useQuery } from '@/graphql';
import { getOmniSearchApolloClient } from '@/graphql/client';
import { useAuth } from '@/utils/auth';

const OmniSearchItemsContext = createContext<Data[]>([]);

const OmniSearchRefetchContext = createContext<
  | {
      refetch: () => Promise<ApolloQueryResult<LayoutOmnisearchQueryQuery>>;
    }
  | undefined
>(undefined);

export const useOmniSearchItems = () => {
  const context = useContext(OmniSearchItemsContext);
  if (context === undefined) {
    throw new Error('useOmniSearchItems must be used within an OmniSearchItemsProvider');
  }
  return context;
};

export const useOmniSearchRefetch = () => {
  const context = useContext(OmniSearchRefetchContext);
  if (context === undefined) {
    throw new Error('useOmniSearchRefresh must be used within an OmniSearchItemsProvider');
  }
  return context;
};

export const OmniSearchItemsProvider = ({ children }: { children?: ReactNode }) => {
  const { isAuthenticated, currentOrganization } = useAuth();
  const { data, loading, refetch } = useQuery(layoutOmnisearchQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    client: getOmniSearchApolloClient(),
    variables: {
      organizationId: currentOrganization?.id || '',
    },
    skip: !currentOrganization,
  });

  const filteredItems: Data[] = useMemo(() => {
    const items: Data[] = [
      {
        sectionLabel: 'Favourites',
        loading,
        items: [],
      },
      {
        sectionLabel: 'Portfolios',
        loading,
        items: [],
      },
      {
        sectionLabel: 'Funds',
        loading,
        items: [],
      },
    ];
    data?.strategyNames.forEach(strategy => {
      if (!strategy) return;
      if (strategy.isFavourite) items[0]?.items.push(mapStrategyToItem(strategy));
      else if (!strategy.isin) items[1]?.items.push(mapStrategyToItem(strategy));
      else items[2]?.items.push(mapStrategyToItem(strategy));
    });
    items[0].items = items[0].items.sort(
      (a, b) => new Date(b.dateCreated || '1970-01-01').getTime() - new Date(a.dateCreated || '1970-01-01').getTime()
    );
    items[1].items = items[1].items.sort(
      (a, b) => new Date(b.dateCreated || '1970-01-01').getTime() - new Date(a.dateCreated || '1970-01-01').getTime()
    );

    const filtered = isAuthenticated ? items : [items[2]];
    return filtered;
  }, [data, loading, isAuthenticated]);

  return (
    <OmniSearchItemsContext.Provider value={filteredItems}>
      <OmniSearchRefetchContext.Provider value={{ refetch }}>{children}</OmniSearchRefetchContext.Provider>
    </OmniSearchItemsContext.Provider>
  );
};

const mapStrategyToItem = (strategy: any) => ({
  id: strategy.isin || strategy.id,
  name: strategy.name,
  url: `${getAppOrigin()}/${strategy.isin ? `funds/${strategy.isin}` : `strategies/${strategy.id}`}`,
  dateCreated: strategy.dateCreated,
});

const layoutOmnisearchQuery = graphql(/* GraphQL */ `
  query LayoutOmnisearchQuery($organizationId: ID!) {
    strategyNames(input: { tags: "webapp", organizationId: $organizationId }) {
      id
      isFavourite
      name
      isin
      dateCreated
    }
  }
`);
