import { useEffect, useState } from 'react';
import { blockedCountriesISO, iso2ToCountryName, type CountryCode } from '@aether/taxonomy';
import { AlertDialog } from '@aether/ui/AlertDialog';
import { Button } from '@aether/ui/Button';
import { getWebsiteOrigin } from '@aether/utils/services/linking-service';

import { PROXY_ENDPOINT } from '@/env';

async function fetchCountry() {
  try {
    return await fetch(`${PROXY_ENDPOINT}/client-info`)
      .then(resp => (resp.ok ? resp.json() : Promise.reject(resp)))
      .then(({ country }) => country);
  } catch (e) {
    console.error(e);
    return Promise.resolve(null);
  }
}
export const CountryController = () => {
  const [isRestrictedCountry, setIsRestrictedCountry] = useState<CountryCode | null>(null);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    fetchCountry().then(country => {
      if (blockedCountriesISO.includes(country)) setIsRestrictedCountry(country);
    });
  }, []);

  if (localStorage.getItem('country-bypass') || !isRestrictedCountry) return;

  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Content overlayOpacity="bg-opacity-50" className="">
        <AlertDialog.Title className="text-lg font-medium">
          Restricted country detection ({iso2ToCountryName[isRestrictedCountry] || isRestrictedCountry})
        </AlertDialog.Title>
        <AlertDialog.Body>
          <div className="flex flex-col gap-4">
            The Portfolio Dragon is currently not available in the country you are trying to access it from, please
            confirm whether you are a citizen of given country.
            <div className="flex justify-end gap-4">
              <AlertDialog.Action asChild onClick={() => {}}>
                <Button
                  onClick={() => {
                    localStorage.removeItem('country-bypass');
                    location.replace(getWebsiteOrigin() || '/');
                  }}
                >
                  I am from {iso2ToCountryName[isRestrictedCountry] || isRestrictedCountry}
                </Button>
              </AlertDialog.Action>
              <AlertDialog.Action
                asChild
                onClick={() => {
                  localStorage.setItem('country-bypass', 'true');
                  setIsOpen(false);
                }}
              >
                <Button>
                  I am <strong>not</strong> from {iso2ToCountryName[isRestrictedCountry] || isRestrictedCountry}
                </Button>
              </AlertDialog.Action>
            </div>
          </div>
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
