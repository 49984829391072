import React from 'react';
import { IconButton } from '@aether/ui/IconButton';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { graphql, useQuery } from '@/graphql';
import { getMaintenanceBannerApolloClient } from '@/graphql/client';

type MaintenanceBannerPayload = { message: string; [key: string]: string } | undefined;

export function MaintenanceBanner() {
  const { data } = useQuery(maintenanceBannerQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    client: getMaintenanceBannerApolloClient(),
    skipPollAttempt: () => document.hasFocus() === false,
  });
  const [isClosed, setClosed] = React.useState(false);

  let message = '**System Maintenance**: Our services is temporarily unavailable';
  const payload = (data?.maintenanceBanner ? { ...data.maintenanceBanner } : undefined) as MaintenanceBannerPayload;

  if (!payload || isClosed) return null;

  // Some extra logic on "to" and "from" fields for fun
  if (payload) {
    // if TO is in the past, we don't show the banner
    if ('to' in payload && new Date(payload.to) < new Date()) return null;
    // if FROM is in the past, we show it as NOW
    if ('from' in payload && new Date(payload.from) < new Date()) payload.from = 'NOW';
    // Message template
    if (payload && 'message' in payload) message = renderTemplate(payload.message, payload);
  }

  return (
    <div className="relative w-full min-w-full bg-warning-100 p-2">
      <IconButton
        icon="ic:close"
        className="absolute right-0 top-0 opacity-50 hover:opacity-100"
        variant="unstyled"
        onClick={() => {
          // localStorage.setItem(`maintenance:${JSON.stringify(payload)}`, 'true'); - for now we don't think it's smart to persist the close
          setClosed(true);
        }}
      />
      <Markdown remarkPlugins={[remarkGfm]} className="text-center">
        {message}
      </Markdown>
    </div>
  );
}

const formatValue = (value: string) => {
  if (!value) return value;
  if (!isNaN(Date.parse(value)))
    return new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'long' }).format(new Date(value));
  else return value;
};

const renderTemplate = (template: string, data: { [key: string]: string }) =>
  template.replace(/\$\{(\w+)\}/g, (_, key) => formatValue(data[key]));

const maintenanceBannerQuery = graphql(/* GraphQL */ `
  query MaintenanceBanner {
    maintenanceBanner
  }
`);
