import { useEffect, useState } from 'react';
import { tracker } from '@aether/tracking';
import { Alert } from '@aether/ui/Alert';
import { Button } from '@aether/ui/Button';
import { Card } from '@aether/ui/Card';
import { Checkbox } from '@aether/ui/Checkbox';
import { ApolloError } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { ApiErrorAlert } from '@/components/ApiErrorAlert';
import { useAuth } from '@/utils/auth';

import { AuthLink } from '../components/AuthLink';

export function ActionSignIn({ continueUrl }: { continueUrl: string }) {
  const { signInWithEmailLink, requestNewInvite } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [tcAccepted, setTcAccepted] = useState(false);
  const [newEmailSent, setNewEmailSent] = useState(false);

  const params = new URLSearchParams(continueUrl.substr(continueUrl.indexOf('?')));
  const linkEmail = params.get('m1');

  useEffect(() => {
    if (linkEmail) tracker.identify(linkEmail);
  }, [linkEmail]);

  const signIn = async () => {
    setLoading(true);
    try {
      await signInWithEmailLink(linkEmail!, window.location.href);
      tracker.capture('invite_success');
      navigate('/account/complete-signup', { replace: true });
    } catch (e: any) {
      tracker.capture('invite_error', { error: JSON.stringify(e) });
      setError(e);
      setLoading(false);
    }
  };

  const handleRequestNewInvite = async () => {
    if (!linkEmail) return;
    tracker.capture('get_new_invite');
    setError(undefined);
    setLoading(true);
    try {
      await requestNewInvite(linkEmail);

      tracker.capture('get_new_invite_success');
      setNewEmailSent(true);
    } catch (e: any) {
      setNewEmailSent(true);
      setError(e);
      tracker.capture('get_new_invite_error', { error: JSON.stringify(e) });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card variant="hero" title="Sign up to Portfolio Dragon." className="m-20 -mx-4 w-full max-w-4xl sm:mx-auto">
      {newEmailSent ? (
        <Alert
          className="mt-8"
          level="info"
        >{`A new invitation link should have been sent to your email (${linkEmail}). Please follow this new link to sign up.`}</Alert>
      ) : !error ? (
        <div className="mt-4 space-y-4">
          <p>
            Please read & agree to our{' '}
            <a href="https://portfoliodragon.com/platform-service-terms" target="_blank">
              terms and conditions
            </a>{' '}
            to accept the invitation and sign up to the web app.
          </p>
          <p>
            <Checkbox
              checked={tcAccepted}
              onCheckedChange={checked => setTcAccepted(Boolean(checked))}
              className="text-sm"
            >
              I have read and agree to the{' '}
              <AuthLink href="https://portfoliodragon.com/platform-service-terms" target="_blank" asChild>
                <a>Terms and Conditions.</a>
              </AuthLink>
            </Checkbox>
          </p>
          <div>
            <Button onClick={signIn} disabled={!tcAccepted} loading={loading} loadingText="Signing up...">
              Accept invitation & Sign up
            </Button>
          </div>
        </div>
      ) : undefined}

      {error && <ApiErrorAlert error={error} className="mt-4" />}
      {error instanceof ApolloError &&
        JSON.stringify(error.graphQLErrors).includes('Invalid or expired action code') && (
          <div className="mt-4">
            <p className="text-base">
              It looks like the sign-up link is already expired. No worries, we can send a new one immediately:
            </p>
            <Button className="mt-4" onClick={handleRequestNewInvite} loading={loading}>
              Send me a new link.
            </Button>
          </div>
        )}
    </Card>
  );
}
