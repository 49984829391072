import * as React from 'react';
import { Alert } from '@aether/ui/Alert';
import { Button } from '@aether/ui/Button';
import { Card } from '@aether/ui/Card';
import { PasswordField } from '@aether/ui/PasswordField';
import { TextField } from '@aether/ui/TextField';
import { passwordSchema } from '@aether/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { ApiErrorAlert } from '@/components/ApiErrorAlert';
import { useAuth } from '@/utils/auth';

const formSchema = z
  .object({
    newPassword: passwordSchema,
    passwordConfirm: z.string(),
  })
  .refine(data => data.newPassword === data.passwordConfirm, {
    message: 'Passwords do not match',
    path: ['passwordConfirm'],
  });

type FormValues = z.infer<typeof formSchema>;

export function ActionSetPassword({ actionCode, continueUrl }: { actionCode: string; continueUrl: string }) {
  const { resetPassword, verifyPasswordResetCode } = useAuth();

  // verifying action code
  const [validatingCode, setValidatingCode] = React.useState(true);
  const [codeError, setCodeError] = React.useState<any>();
  React.useEffect(() => {
    verifyPasswordResetCode(actionCode)
      .catch(error => {
        console.error(error);
        setCodeError(error);
      })
      .finally(() => setValidatingCode(false));
  }, [actionCode, verifyPasswordResetCode]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      newPassword: '',
      passwordConfirm: '',
    },
  });

  const [passwordError, setPasswordError] = React.useState<any>();
  const [success, setSuccess] = React.useState(false);

  const decodedContinueURL = decodeURIComponent(continueUrl);
  const initialEmail = decodedContinueURL.substr(decodedContinueURL.indexOf('email=') + 'email='.length);

  const setNewPassword = async (values: FormValues) => {
    setPasswordError(undefined);
    try {
      await resetPassword({ oobCode: actionCode, newPassword: values.newPassword });
      setSuccess(true);
    } catch (e: any) {
      setPasswordError(e);
      setSuccess(false);
    }
  };

  return (
    <Card
      variant="hero"
      loading={validatingCode}
      className="m-20 -mx-4 w-full max-w-xl sm:mx-auto"
      title="Reset your password"
    >
      <div className="flex flex-col gap-8 py-4">
        {codeError ? (
          <>
            <Alert level="error" title="Invalid link">
              The password reset link has expired or is invalid. This could be caused by the link already having been
              used once, or a new password reset having been requested.
            </Alert>
            <div>
              <Button asChild>
                <Link to={`/auth/reset-password?email=${initialEmail}`}>Request a new password reset link</Link>
              </Button>
            </div>
          </>
        ) : success ? (
          <>
            <Alert level="success" title="Your new password has been set">
              Your password has been set. You can now log in with your new password.
            </Alert>
            <p>
              <Button variantColor="accent" asChild iconBefore="ic:outline-arrow-back">
                <Link to="/auth/login">Go to Login</Link>
              </Button>
            </p>
          </>
        ) : (
          <>
            <p>
              Please choose a new password to log in to Portfolio Dragon. This should be a unique password that you
              don't use anywhere else.
            </p>
            <form onSubmit={handleSubmit(setNewPassword)}>
              <div className="flex flex-1 flex-col gap-4">
                <Controller
                  control={control}
                  name="newPassword"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      autoFocus
                      iconBefore="ic:outline-key"
                      label="Choose a new password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      errorMessage={error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="passwordConfirm"
                  render={({ field, fieldState: { error } }) => (
                    <PasswordField
                      {...field}
                      iconBefore="ic:outline-key"
                      label="Confirm password"
                      id="pwconfirm"
                      type="password"
                      autoComplete="new-password"
                      errorMessage={error?.message}
                    />
                  )}
                />
                {passwordError && <ApiErrorAlert error={passwordError} />}
                <div>
                  <Button type="submit" loading={isSubmitting} loadingText="Setting new password...">
                    Set new password
                  </Button>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </Card>
  );
}
