import * as React from 'react';

import { useAuth } from '@/utils/auth';

export const Logout = () => {
  const [isSlow, setIsSlow] = React.useState(false);
  const { signOut } = useAuth();

  signOut();

  React.useEffect(() => {
    setTimeout(() => setIsSlow(true), 15000);
  }, []);

  return (
    <div className="flex h-screen">
      <div className="m-auto w-full max-w-3xl">
        Please allow us a some time to log you out…
        {isSlow && (
          <>
            Seems as if this is taking longer than expected, please{' '}
            <a href="/" className="text-blue-500">
              click here
            </a>
          </>
        )}
      </div>
    </div>
  );
};
