import * as React from 'react';
import * as Ariakit from '@ariakit/react';
import { cva, type VariantProps } from 'class-variance-authority';

import { Icon } from '../Icon';
import { cn } from '../utils';

export const MenuProvider = Ariakit.MenuProvider;
export const MenuButton = Ariakit.MenuButton;

// MENU ITEMS WRAPPER
export interface MenuProps extends Ariakit.MenuProps, VariantProps<typeof menuStyle> {
  alignWithIcons?: boolean;
  isSubMenu?: boolean;
}

export const Menu = React.forwardRef<HTMLDivElement, MenuProps>((props, forwardRef) => {
  const { className, variant, alignWithIcons, isSubMenu, ...rest } = props;
  return (
    <Ariakit.Menu
      gutter={2}
      shift={isSubMenu ? -4 : alignWithIcons ? -20 : 0}
      portal
      unmountOnHide
      {...rest}
      className={cn(menuStyle({ variant }), alignWithIcons ? 'align-icons' : '', className)}
      ref={forwardRef}
    />
  );
});

const menuStyle = cva('z-50 flex flex-col rounded py-1 shadow-lg outline-none', {
  variants: {
    variant: {
      default: 'border border-grey-100 bg-white px-1',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

// MENU ITEMS
export interface MenuItemProps extends Omit<Ariakit.MenuItemProps, 'disabled'>, VariantProps<typeof itemStyle> {
  iconBefore?: string | React.ReactElement;
}

export const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>((props, forwardRef) => {
  const { className, variant, disabled, iconBefore, children, onSelect, onClick, ...rest } = props;
  return (
    <Ariakit.MenuItem
      onClick={onSelect ?? onClick}
      {...rest}
      disabled={disabled ?? false}
      className={cn(
        itemStyle({ variant, disabled }),
        iconBefore && 'pl-1.5',
        !iconBefore && variant !== 'unstyled' ? '[.align-icons_&]:pl-7' : '',
        className
      )}
      ref={forwardRef}
    >
      {iconBefore &&
        (typeof iconBefore === 'string' ? <Icon className="text-base leading-6" icon={iconBefore} /> : iconBefore)}
      {children}
    </Ariakit.MenuItem>
  );
});

const itemStyle = cva('', {
  variants: {
    variant: {
      unstyled: '',
      default:
        'flex items-center gap-1.5 rounded px-3 py-0.5 text-sm leading-6 text-grey-800 outline-none hover:bg-grey-50 hover:text-black focus-visible:bg-grey-50 focus-visible:text-black',
    },
    disabled: {
      true: 'cursor-not-allowed text-grey-200',
      false: 'cursor-pointer',
    },
  },
  defaultVariants: {
    variant: 'default',
    disabled: false,
  },
});

// MENU SEPARATOR

export const MenuSeparator = React.forwardRef<HTMLHRElement, React.HTMLAttributes<HTMLHRElement>>(
  (props, forwardRef) => {
    const { className, ...rest } = props;
    return (
      <Ariakit.MenuSeparator className={cn('my-1 border-t border-grey-150', className)} {...rest} ref={forwardRef} />
    );
  }
);

// MENU GROUP
export const MenuGroup = Ariakit.MenuGroup;

export const MenuGroupLabel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props, forwardRef) => {
    const { className, ...rest } = props;
    return (
      <Ariakit.MenuGroupLabel
        className={cn('px-3 py-1 text-xs font-medium leading-4 text-grey-400 [.align-icons_&]:pl-7', className)}
        {...rest}
        ref={forwardRef}
      />
    );
  }
);
