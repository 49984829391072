import { useEffect } from 'react';
import { Alert } from '@aether/ui/Alert';
import { Button } from '@aether/ui/Button';
import { Card } from '@aether/ui/Card';
import { Heading } from '@aether/ui/Heading';
import { RadioGroup } from '@aether/ui/RadioGroup';
import { TextField } from '@aether/ui/TextField';
import { cn } from '@aether/ui/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { PageSeoTitle } from '@/components/PageSeoTitle';
import { graphql, useMutation, useQuery } from '@/graphql';
import { getAuthApolloClient } from '@/graphql/client';
import { useAuth } from '@/utils/auth';

export function CreateOrganization() {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  useEffect(() => {
    if (!user || !isAuthenticated) {
      return navigate('/auth/login');
    }
    if (user.organizations.length > 0) {
      return navigate('/welcome');
    }
  }, [user, isAuthenticated, navigate]);
  if (!user || !isAuthenticated) return null;
  return <CreateOrganizationForm emailDomain={user.email.split('@')[1]} orgName={user.declaredOrg || ''} />;
}

const formSchema = z.object({
  name: z.string().min(1),
  emailDomain: z.string().min(1),
  plan: z.string().min(1),
});

type FormData = z.infer<typeof formSchema>;

const plans = [
  {
    name: 'Free',
    trial: false,
    value: '0x3',
    contactSales: false,
    price: '',
    description: 'Free access to our funds overviews.',
    featuresTitle: '',
    features: ['Explore the thousands of funds in our database', 'Create a selection by bookmarking them'],
  },
  {
    name: 'Web Access',
    trial: 30,
    value: '0x4',
    contactSales: false,
    price: '$25k',
    description: 'Access to all features of our web interface.',
    featuresTitle: '',
    features: [
      'Upload portfolio',
      'Build new portfolio',
      'Portfolio analytics',
      'Portfolio sustainability report',
      'AI portfolio rebalance',
      'Portfolio factsheets',
      'Backtesting',
      'Compare portfolios',
      'Export holdings',
      // 'Factsheet templates customisation',
    ],
  },
  {
    name: 'Web + API Access',
    trial: 30,
    value: '0x5',
    contactSales: false,
    price: '$50k',
    description: 'A plan that scales with your rapidly growing business.',
    featuresTitle: 'Everything in web access, plus:',
    features: [
      'Access to 300+ API endpoints',
      'Python API SDK',
      'Multiple API keys',
      'Bespoke portfolio optimisation',
      'Advanced portfolio construction',
      'Delivery and workflow services',
      'IAM management',
    ],
  },
  {
    name: 'Dedicated instance',
    trial: false,
    value: '0x6',
    contactSales: true,
    price: '$325k',
    description: 'Dedicated infrastructure and support for your company.',
    featuresTitle: 'Everything in web and API access, plus:',
    features: [
      'Dedicated infrastructure and customised environment',
      'High level of customisation',
      'Custom branding',
      'SSO integration',
      'Dedicated support',
    ],
  },
] as const;

function CreateOrganizationForm(props: { emailDomain: string; orgName: string }) {
  const client = getAuthApolloClient();
  const { data, loading, error } = useQuery(canCreateOrganizationQuery, { client });
  const navigate = useNavigate();
  const [createOrganization, { loading: createOrganizationLoading, error: createOrganizationError }] = useMutation(
    createOrganizationMutation,
    {
      client,
      refetchQueries: ['AuthQuery', 'CurrentOrganizationQuery'],
      awaitRefetchQueries: true,
    }
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: props.orgName,
      emailDomain: props.emailDomain,
      plan: plans[1].value,
    },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  const canCreateOrganization = data?.canCreateOrganization;
  function onSubmit(data: FormData) {
    return createOrganization({ variables: { input: { name: data.name, plan: data.plan } } });
  }
  return (
    <Card variant="hero" title="Register your organization" className="m-20 -mx-4 max-w-2xl sm:mx-auto">
      <PageSeoTitle title="Register your organization" />
      {!canCreateOrganization ? (
        <div className="mt-4 space-y-4">
          <Alert level="warning" title="Organization already registered">
            <p>
              An organization is already registered for the email domain{' '}
              <span className="font-bold">{props.emailDomain}</span>.
            </p>
            <p>Please contact your organisation administrator to add your account to this organization.</p>
          </Alert>
          <p>
            If you want to create an organization with a different email domain, you first need to register with an
            email address that matches that domain.
          </p>
        </div>
      ) : (
        <form className="mt-4 space-y-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <Heading level={2}>Organization details:</Heading>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <TextField label="Organization name" {...field} />}
            />
            <Controller
              control={control}
              name="emailDomain"
              render={({ field }) => (
                <TextField
                  label="Organization email domain"
                  iconBefore="ic:outline-alternate-email"
                  description={
                    <div className="space-y-1 text-xs">
                      <p>
                        New accounts with emails matching this domain will be blocked in their sign-up process, until an
                        organization admin validates their account.
                      </p>
                    </div>
                  }
                  readOnly
                  {...field}
                />
              )}
            />
          </div>
          {/* <div className="space-y-4">
            <Heading level={2}>Select a plan:</Heading>
            <Controller
              name="plan"
              control={control}
              render={({ field }) => (
                <RadioGroup.Group
                  groupClassName="grid grid-cols-4 items-stretch gap-4"
                  wrapperClassName="p-0"
                  value={field.value}
                  onValueChange={field.onChange}
                >
                  {plans.map(plan => (
                    <RadioGroup.Radio
                      key={plan.value}
                      value={plan.value}
                      className={cn(
                        'rounded-lg border border-gray-900 p-4 hover:border-portfolio-500',
                        plan.value === field.value && 'border-2 border-portfolio-500'
                      )}
                      radioClassName="hidden"
                      disabled={plan.contactSales}
                    >
                      <div className="flex h-full cursor-pointer flex-col justify-between">
                        <div>
                          <p className="text-xl font-semibold leading-8 text-gray-900">{plan.name}</p>
                          <p className="mt-4 text-sm leading-6 text-gray-600">{plan.description}</p>
                          <p className="mt-6 flex items-baseline gap-x-1">
                            <span className="text-2xl font-bold tracking-tight text-gray-900">{plan.price}</span>
                            {plan.price && (
                              <span className="text-sm font-semibold leading-6 text-gray-600">/year + VAT</span>
                            )}
                          </p>
                          <div className="my-8 space-y-2">
                            {plan.featuresTitle && (
                              <p className="text-md font-medium text-gray-900">{plan.featuresTitle}</p>
                            )}

                            <ul role="list" className="space-y-2 text-sm leading-6 text-gray-600">
                              {plan.features.map(feature => (
                                <li className="flex gap-x-3">
                                  <svg
                                    className="h-6 w-5 flex-none text-indigo-600"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  {feature}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <Button
                          className="mt-auto"
                          variantColor={plan.value === field.value ? 'accent' : 'secondary'}
                          variant={plan.value === field.value ? 'plain' : 'ghost'}
                          onClick={() => (plan.contactSales ? undefined : field.onChange(plan.value))}
                        >
                          {plan.contactSales ? 'Contact sales' : plan.trial ? 'Start free trial' : 'Select plan'}
                        </Button>
                      </div>
                    </RadioGroup.Radio>
                  ))}
                </RadioGroup.Group>
              )}
            />
          </div> */}
          <Button type="submit" loading={isSubmitting} loadingText="Creating organization...">
            Create organization
          </Button>
        </form>
      )}
    </Card>
  );
}

const canCreateOrganizationQuery = graphql(/* GraphQL */ `
  query CanCreateOrganization {
    canCreateOrganization
  }
`);

const createOrganizationMutation = graphql(/* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      ...CurrentOrganization
    }
  }
`);
