import * as React from 'react';
import { tracker } from '@aether/tracking';
import { DisclaimerBanner } from '@aether/tracking/disclaimer-banner';
import { cn } from '@aether/ui/utils';
import { Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '@/utils/auth';

import { AuthController } from '../controllers/AuthController';
import { CountryController } from '../controllers/CountryController';
import { PageLoaderController } from '../controllers/PageLoaderController';

export default function RootLayout() {
  const { user } = useAuth();
  return (
    <div className={cn('flex h-full flex-col', user?.impersonatedBy && 'border-8 border-red-500 print:border-none')}>
      {user?.impersonatedBy && (
        <div className="fixed left-1/2 top-0 z-30 -translate-x-1/2 rounded-b bg-red-500 px-2 text-body font-bold text-white print:hidden">
          On behalf of {user.email}
        </div>
      )}
      <Outlet />
      <PageLoaderController />
      <AuthController />
      <CountryController />
      <DisclaimerBanner />
      <PageViewTracker />
    </div>
  );
}

function PageViewTracker() {
  const location = useLocation();
  React.useEffect(() => {
    tracker.capture('$pageview');
  }, [location]);
  return null;
}
